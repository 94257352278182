(function ($) {
  const default_arrows = {
    left: '<button class="slick-prev"><i class="fas fa-chevron-left"></i><span class="sr-only">Previous</span></button>',
    right:
      '<button class="slick-next"><i class="fas fa-chevron-right"></i><span class="sr-only">Next</span></button>',
  };

  jQuery(document).ready(function () {
    $(".carousel-slick-small").slick({
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      prevArrow: default_arrows.left,
      nextArrow: default_arrows.right,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $(".carousel-slick-product-relate").slick({
      infinite: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      prevArrow: default_arrows.left,
      nextArrow: default_arrows.right,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    });

    $(".carousel-slick-large").slick({
      infinite: true,
      slidesToShow: 8,
      slidesToScroll: 1,
      prevArrow: default_arrows.left,
      nextArrow: default_arrows.right,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 8,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 780,
          settings: {
            slidesToShow: 6,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });
})(jQuery);
