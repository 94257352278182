jQuery(function ($) {
  jQuery(document).ready(function () {
    $("#seach-button-header").click(function () {
      $("#s").focus();
      setTimeout(() => {
        $("#s").keyup();
      }, 500);
    });
  });

  jQuery(window).on('resize', function() {
    jQuery('html').click();
  });
});
